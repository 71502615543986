<template>
  <b-sidebar
    id="add-new-role-sidebar"
    :visible="isAddNewRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-add-new-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Үүрэг нэмэх
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Үүргийн нэр"
            rules="required"
          >
            <b-form-group
              label="Үүргийн нэр"
              label-for="role-name"
            >
              <b-form-input
                id="role-name"
                v-model="roleLocal.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Үүргийн нэр"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="Дараалал"
            rules="required"
          >
            <b-form-group
              label="Дараалал"
              label-for="sort-order"
              :state="getValidationState(validationContext)"
            >
              <b-form-spinbutton
                id="sort-order"
                v-model="roleLocal.sort_order"
                min="1"
                max="100"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Нэмэх
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Цуцлах
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSpinbutton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
extend('required', {
  ...required,
  message: 'Заавал бөглөх талбар бөглөөгүй байна'
});
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import { toRefs } from '@vue/composition-api'
import store from '@/store'
import useRoleHandler from '../useRoleHandler'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSpinbutton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRoleSidebarActive',
    event: 'update:is-add-new-role-sidebar-active',
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    status: {
      type: Object,
      required: false,
    },
    clearRoleData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    
    const {
      roleLocal,
      resetRoleLocal,
      onSubmit
    } = useRoleHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm
    } = formValidation(resetRoleLocal, props.clearRoleData)

    return {
      roleLocal,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      clearForm
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
../useRoleHandler