import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useRoleHandler(props, emit) {
    // ------------------------------------------------
    // taskLocal
    // ------------------------------------------------
    const roleLocal = ref(JSON.parse(JSON.stringify(props.role.value)))
    const resetRoleLocal = () => {
      roleLocal.value = JSON.parse(JSON.stringify(props.role.value))
    }
    watch(props.role, () => {
      resetRoleLocal()
    })
  
    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ! We can hide it using @hidden event
    // ------------------------------------------------
    // watch(props.isEventHandlerSidebarActive, val => {
    //   // ? Don't reset event till transition is finished
    //   if (!val) {
    //     setTimeout(() => {
    //       clearForm.value()
    //     }, 350)
    //   }
    // })
  
    const onSubmit = () => {
      const roleData = JSON.parse(JSON.stringify(roleLocal))
  
      // * If event has id => Edit Event
      // Emit event for add/update event
      if (props.role.value.id) emit('update-role', roleData.value)
      else emit('add-role', roleData.value)
  
      // Close sidebar
      emit('update:is-add-new-role-sidebar-active', false)
    }
  
  
    return {
      roleLocal,
      resetRoleLocal,
      onSubmit,
    }
  }